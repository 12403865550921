<template>
  <div class="content">
    <div class="header">
      <svg
        v-if="!isCollapse"
        class="icon"
        aria-hidden="true"
        @click="changeMenu"
      >
        <use xlink:href="#icon-caidanguanli"></use>
      </svg>
      <svg v-else class="icon" aria-hidden="true" @click="changeMenu">
        <use xlink:href="#icon-caidan"></use>
      </svg>
      <div class="header-right">
        <span>欢迎您：{{ loginData.name }}</span>
        <el-image
          class="image-exit"
          @click="loginOut"
          :src="require(`../../../assets/image/exit.png`)"
          fit="fit"
        ></el-image>
      </div>
    </div>
    <div class="content-route">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: ["isCollapse"],
  computed: {
    ...mapState("m_login", ["loginData"]),
  },
  methods: {
    ...mapMutations("m_login", ["clearUser"]),
    changeMenu() {
      // 点击切换按钮 修改父组件数据
      this.$emit("changeCollapse");
    },
    loginOut() {
      this.clearUser();
      localStorage.removeItem("usersinfomation");
      this.$router.push("/aiAdminLogin");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-flow: column;
  .content-route {
    flex: 1;
    background-color: #ecf4ff;
  }
  .header {
    min-height: 56px;
    background-image: linear-gradient(to right, #568ee3, #0c125c);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    .icon {
      font-size: 40px;
    }
    .header-right {
      display: flex;
      align-items: center;
      .image-exit {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
</style>
