<template>
  <div class="layout">
    <Mymenu class="menu" :isCollapse="isCollapse" />
    <Content class="content" :class="{isActive: isCollapse}" @changeCollapse="changeCollapse" :isCollapse="isCollapse" />
  </div>
</template>

<script>
import Mymenu from './components/Mymenu.vue'
import Content from './components/Content.vue'

export default {
  components: {
    Mymenu,
    Content
  },
  data () {
    return {
      isCollapse: false
    }
  },
  methods: {
    changeCollapse () {
      this.isCollapse = !this.isCollapse
    }
  }
}
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  .menu {
    background-color: #568ee3;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
  }
  .content {
    height: 100%;
    margin-left: 200px;
    transition: margin-left ease-in-out 0.3s;
  }
  .isActive {
    margin-left: 64px;
  }
}
</style>