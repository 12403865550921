<template>
  <div class="mymenu">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#568ee3"
      text-color="#fff"
      router
      :collapse="isCollapse"
    >
      <el-menu-item>
        <span slot="title">大嘴鸟AI管理后台</span>
      </el-menu-item>
      <el-menu-item index="/aiHome">
        <i class="el-icon-menu icon"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="/aiChain">
        <template slot="title">
          <i class="el-icon-office-building icon"></i>
          <span>学校管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/aiChain/list">学校列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/aiUser">
        <template slot="title">
          <i class="el-icon-user icon"></i>
          <span>用户管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/aiUser/list">用户列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/aiClass">
        <template slot="title">
          <i class="el-icon-s-custom icon"></i>
          <span>班级管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/aiClass/class">班级</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/aiCompetition">
        <template slot="title">
          <i class="el-icon-wind-power icon"></i>
          <span>竞赛管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/aiCompetition/list">简历大赛评审</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!-- <el-submenu index="/aiSuperAdmin">
        <template slot="title">
          <i class="el-icon-user icon"></i>
          <span>超管</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/aiSuperAdmin/authenticationList"
            >企业认证申请列表</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu> -->

      <el-menu-item
        index="/aiAuthenticationList"
        v-if="usersinfomation.ai_chain_id === 4"
      >
        <i class="el-icon-user icon"></i>
        <span slot="title">企业认证申请列表</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: ["isCollapse"],
  data() {
    return {
      usersinfomation: {}, // 用户信息
    };
  },
  methods: {
    init() {
      const userInfo = localStorage.getItem("usersinfomation");
      if (userInfo) {
        this.usersinfomation = JSON.parse(userInfo);
        console.log(this.usersinfomation, "user");
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.mymenu {
  overflow-x: auto;
  height: 100%;

  .el-menu {
    border-right: 0;

    .is-active {
      background: #4a81d0 !important;
      color: #fff !important;
    }
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

  .icon {
    color: #f4d32c;
  }
}
</style>
